import React from "react";
import { Link } from "gatsby";

export default function SectionWButton({ title, paragraph, children, button }) {
  return (
    <section className="SectionWButton-Container content-block">
      <h2>{title}</h2>
      <p>{paragraph}</p>
      {children}
      <Link className="btn" to={button.href} aria-label={button.ariaLabel}>
        {button.text}
      </Link>
    </section>
  );
}
