import React from "react";
import { graphql } from "gatsby";

import SEO from "../components/seo";

import SectionWTagline from "../components/SectionWTagline";
import SectionWButton from "../components/SectionWButton";
import Header from "../components/Header";
import ImageBar from "../components/ImageBar";

export default function IndexPage({ data }) {
  const { markdownRemark } = data;
  const { frontmatter } = markdownRemark;

  return (
    <main>
      <SEO
        title={frontmatter.seotitle ? frontmatter.seotitle : "Inland Northwest Technologists"}
        keywords={[`Inland Northwest Technologists`, `application`, `react`]}
        description={frontmatter.seodescription}
      />
      <Header {...frontmatter} />
      {frontmatter.sections.map((section) => {
        switch (section.type) {
          case "sectionwtagline":
            return (
              <SectionWTagline
                title={section.heading}
                paragraph={section.body}
                subtitle={section.tagline}
                key={`${section.type}${section.heading}`}
              />
            );
          case "sectionwbutton":
            return (
              <SectionWButton
                title={section.heading}
                paragraph={section.body}
                button={{ href: section.buttonurl, ariaLabel: section.buttontext, text: section.buttontext }}
                key={`${section.type}${section.heading}`}
              />
            );
          case "imagebar":
            return <ImageBar banner={section.banner} key={`${section.type}${section.banner}`} />;
          default:
            return null;
        }
      })}
    </main>
  );
}

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        title
        subtitle
        banner {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 75) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        sections {
          type
          heading
          body
          tagline
          buttontext
          buttonurl
          banner {
            childImageSharp {
              fluid(maxWidth: 1920, quality: 75) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`;
