import React from "react";

export default function SectionWTagline({ title, paragraph, subtitle, children }) {
  return (
    <div className="SectionWTagline-Container content-block" id="content">
      <h2>{title}</h2>
      <p>{paragraph}</p>
      <h3>{subtitle}</h3>
      <hr />
      {children}
    </div>
  );
}
