import React from "react";

export default function ImageBar({ banner, children }) {
  return (
    <div className="events-header-container">
      <div className="events-header" style={{ backgroundImage: `url(${banner.childImageSharp.fluid.src})` }}>
        {children}
      </div>
    </div>
  );
}
